body {
  margin: 0;
  min-height: 100vh;
}

#root {
  height: 100%;
  min-height: 100vh;
}

/* Override the Unity pagination styles. Remove background for non-active pagination buttons */
.page-item:not(.active) .page-link {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* Restore light gray background on hover, focus, and active states */
.page-item:not(.active) .page-link:is(:hover, :focus, :active) {
  background-color: #e9ecef !important; /* Bootstrap's default light gray */
}